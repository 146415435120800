import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, Container } from '@material-ui/core'
import * as Accessa from 'accessa'
import { get } from 'lodash'
import {
  ArchivePagination,
  BlogCategorySelect,
  withGlobals,
} from '../components'
import { toFeaturePropsShape } from '../components/ArchiveLayoutBlogs'

export const Archive = ({
  contentfulCategory,
  allContentfulBlog,
}) => {
  return (
    <Box pt={5} pb={5}>
      <Accessa.Headers.Mast
        title={contentfulCategory.name}
      />
      <Box mt={5}>
        <Container>
          <BlogCategorySelect
            active={contentfulCategory.relativePath}
          />
          <Accessa.Features.Field
            lists={toFeaturePropsShape(
              get(allContentfulBlog, 'nodes', [])
            )}
          />
          <ArchivePagination
            {...allContentfulBlog}
            {...contentfulCategory}
          />
        </Container>
      </Box>
    </Box>
  )
}

Archive.propTypes = {
  // eslint-disable-next-line
  contentfulCategory: PropTypes.object.isRequired,
  // eslint-disable-next-line
  allContentfulBlog: PropTypes.object,
}

export const query = graphql`
  query getContentfulCategoryById(
    $contentful_id: String
    $node_locale: String
    $skip: Int
    $limit: Int
  ) {
    contentfulCategory(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      name
      relativePath
    }

    allContentfulBlog(
      skip: $skip
      limit: $limit
      filter: {
        node_locale: { eq: $node_locale }
        categories: {
          elemMatch: {
            contentful_id: { eq: $contentful_id }
          }
        }
      }
    ) {
      nodes {
        title
        image {
          fluid {
            srcSet
          }
        }
        node_locale
        relativePath
        categories {
          featuredImage {
            fluid {
              src
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        currentPage
        totalCount
      }
    }

    hreflang: allContentfulCategory(
      filter: {
        contentful_id: { eq: $contentful_id }
        node_locale: { ne: $node_locale }
      }
    ) {
      nodes {
        node_locale
        relativePath
      }
    }
  }
`

export default withGlobals(Archive)
